.row {
    margin-bottom: 50px;
}
.column {
    flex: 50%;
    height: 100%;
    margin-top: 10px;
    margin-left: 5%;
    margin-right: 5%;
    
    
}
.title {
    text-decoration: none;
}
.img {
    width: 60%;
    padding-top: 20px;
}
.text {
    text-decoration: none;
    text-align: center;
    color:rgb(37, 37, 37); 
    text-align: justify;
    margin-right: 30px;
}
@media screen and (max-width: 960px) {
   
    .row{
        flex-direction: column;
        position: relative;
        margin-bottom: 50px;
    }

}