.navbar { 
    background: linear-gradient(90deg, rgb(28,27,27) 0%, rgb(26,23,23) 100%);
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 999;
}
.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
}
.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 8px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
}
.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
}
.nav-item { 
    
    height: 80px;
}
.nav-links{
    
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.2rem 1rem;
    height: 100%;
}
.nav-links:hover {
    color: #bf930d;
    border-bottom: 4px solid #bf930d;
    transition: all 0.2s ease-out ;
}
.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}
.fa-bars {
    color: #fff;
}
.fa-times {
    color: #fff;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
        
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 101%;
        height: 110vh;
        position: absolute;
        top: 50px;
        left: -120%;
        opacity: 1;
        transition: all 0.5s ease;

    }
    .nav-menu.active {
        background: #242222;
        left: -9%;
        opacity: 1;
        transition: all 0.2s ease-out;
        z-index: 1;
    }
    
    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 85%;
        height: 10px;
        display: table;
    }
    
    .nav-links:hover {
        background-color: #fff;
        color: #bf930d;
        border-radius: 0;
    }
    
    .navbar-logo {
        position: absolute;
        top: 5px;
        left: 0;
        transform: translate(25%, 50%);
    }
    
    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
      }
    
    .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
        transition: 250ms;
    }
}