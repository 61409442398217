.row {
    margin-bottom: 50px;
}
.column {
    display: inline-block;
    flex: 50%;
    height: 100%;
    margin-top: 10px;
    margin-left: 5%;
    margin-right: 5%;
}
    
.products {
    display: table;
    flex-direction: column;
    border-radius: 1px;
    background-color: transparent;
    margin-bottom: 15px;
    padding: 10px;
    width: 180px;
    height: 150px;
    text-align: center;
    margin-left: 50px;
    
}
.img {
    display: center;
    height: 101%;
    width: 101%;
}
h3 {
    text-decoration: none !important;
    color: rgb(87, 84, 84);
    font-size: 1rem;

}
a{
    text-decoration: none;
}
.text {
    text-align: justify;
}
@media screen and (max-width: 960px) {
   
    .row{
        flex-direction: column;
        position: relative;
        
    }
    .column {
        margin-bottom: 50px;
    }
    .column > .products {
        display: inline-block;
    }

    
}